import React from "react";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import StickyHeader from "../components/header/StickyHeader";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";

function VulnerabilityAssessmentService() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Audit Service"
            bannerTitle="Vulnerability Assessment Service"
            bannerPara="We possess the necessary skills and expertise to conduct effective vulnerability assessment testing"
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      {/* <InnerPageBanner /> */}
      <section className="conetentSection">
        <AboutPage
          line1="Protecting your business from potential cyber threats and safeguarding your valuable data is paramount in today’s digital landscape. At Vodote Techa, we offer top-notch vulnerability assessment services to ensure the utmost security for your firm. Our team stays up-to-date with the latest developments in the security space, leaving no vulnerability undiscovered."
          line2="With precision and thoroughness, we conduct comprehensive vulnerability assessment tests, providing our clients with a delightful and secure experience. Our track record speaks for itself, as none of our clients has faced security problems after consulting with us. When it comes to reliable VAPT services in Mumbai, Vodote Techa is the name to trust. We possess the necessary skills and expertise to conduct effective vulnerability assessment testing for your entire system architecture."
          line3="Our team of highly qualified security experts excels in performing vulnerability assessment and penetration testing across various infrastructures."
          line4="With years of experience under Vodote Techa, they are well-equipped to handle your business’s security needs. Entrusting your security to professionals is vital, as your employees may lack the skills required to identify all existing loopholes. Vodote Techa excels in handling every aspect of your business’s security, making us the trusted choice for renowned brands."
          line5="We understand that many businesses rely on highly confidential and sensitive data, which must not be compromised under any circumstances. That’s why our team of qualified experts possesses in-depth knowledge of security practices, staying abreast of the latest trends in the IT sector. When it comes to VAPT companies in Mumbai, you won’t find a better option than us. Our effective methodologies ensure that your business remains secure and protected."
          line6="If you’re seeking vulnerability assessment services in Mumbai, look no further than Vodote Techa. We prioritize covering all security loopholes, ensuring that your business remains impervious to any potential threats. Contact us today to find a comprehensive solution for all your security-related concerns."
          abImg={otherImg}
          altName="Vulnerability Assessment Service"
        />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default VulnerabilityAssessmentService;
