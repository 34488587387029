import React from "react";

function RightContentBox(props) {
  return (
    <div className="right_anime_box">
      <h6>
        <span>
          <img
            src={props.imgIcon}
            alt={props.animeMainTitle}
            className="img-fluid"
          />
        </span>
        {props.animeSmallTitle}
      </h6>
      <h3>{props.animeMainTitle}</h3>
      <p>{props.animePara}</p>
      <a href={props.consultLink} className={props.btnClass}>
        {props.greenBtnLinkText}
      </a>
    </div>
  );
}

export default RightContentBox;
