import React from "react";

function GreenBtn(props) {
  return (
    <button type="submit" className="btn w-100 formBtn">
      {props.greenBtnText}
    </button>
  );
}

export default GreenBtn;
