import React from "react";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import StickyHeader from "../components/header/StickyHeader";
import QuestionAnswers from "../components/innerpagecomponents/QuestionAnswers";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";

function SpearPhishing() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Security Service"
            bannerTitle="Spear Phishing"
            bannerPara="The internet is vulnerable to tricks that can harm users' systems through deceptive links and downloads."
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      <section className="conetentSection">
        <AboutPage
          line1="The internet is susceptible to numerous deceptive tactics designed to trick users into clicking on harmful links or downloading malicious files, potentially damaging their systems. This issue is pervasive and impacts a vast majority of internet users. Shockingly, it has been reported that an overwhelming 91% of hacking attempts commence with phishing, as it proves quite effective at deceiving ordinary users."
          line2="Recently, spear phishing email campaigns have grown more targeted and are achieving higher conversion rates. Common users often struggle to distinguish between genuine emails or messages and fraudulent ones. This is the principal reason behind the rising success of spear phishing attacks."
          abImg={otherImg}
          altName="Spear Phishing"
        />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <section className="ourMethodologySection">
        <div className="container">
          <QuestionAnswers
            que1="What sets Vodote Techa apart for Spear Phishing services?"
            ans11="If you're a business owner, it's crucial to initiate the education of your employees on recognizing spear phishing messages. Most hacker groups tend to follow a specific pattern, and once you become familiar with it, the ability to prevent such campaigns from harming your company significantly improves."
            ans12="Vodote Techa is fully equipped with the appropriate technologies and skilled professionals to offer you solutions that effectively lead to spear phishing prevention. We excel in providing the most robust spear phishing protection measures, allowing you to secure your business against these malicious attacks."
            que2="What distinguishes Phishing from Spear Phishing?"
            ans21="The key distinction between phishing and spear phishing attacks is that the former typically pretends to be from a reputable company, while the latter poses as an email from an individual in a position of authority. Nevertheless, both types of attacks can have similar consequences for your company or employees. The techniques employed by Vodote Techa to address these threats will also bear similarities, as they both fall within a specific category of hacking attempts."
            ans22="Vodote Techa, a premier security agency in Mumbai, provides exceptional services tailored to a variety of needs. We offer top-tier information security awareness training for your employees or professionals. These lessons are best-in-class and are designed to help prevent various types of spear phishing attacks."
            
          />
        </div>
      </section>
      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default SpearPhishing;
