import React from "react";
import { featIcon1, featIcon2 } from "..";
const featureData = [
  {
    id: 0,
    feat_icon: featIcon1,
    feat_title: "Managed Detection",
    feat_description:
      "Expert guided security operations for early detection and automated response to mitigate risk across the enterprise.",
  },
  {
    id: 1,
    feat_icon: featIcon1,
    feat_title: "Vulnerability Management",
    feat_description:
      "Efficiently discover and remediate critical threats and vulnerabilities across the enterprise.",
  },
  {
    id: 2,
    feat_icon: featIcon2,
    feat_title: "Secure File Storage",
    feat_description:
      "When it comes to storing your data, it is important to know if the proper security and privacy measures are in place.",
  },
  {
    id: 3,
    feat_icon: featIcon2,
    feat_title: "Cloud Email Solution",
    feat_description:
      "When it comes to storing your data, it is important to know if the proper security and privacy measures are in place.",
  },
];
function FeatureBlock() {
  return (
    <div className="row">
      {featureData.map((item, index) => {
        return (
          <div className="col-md-6" key={index}>
            <div className="featBoxWrap">
              <div className="iconWrap">
                <img
                  src={item.feat_icon}
                  alt={item.feat_title}
                  className="img-fluid"
                />
              </div>
              <div className="featContentBox">
                <h5>{item.feat_title}</h5>
                <p>{item.feat_description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default FeatureBlock;
