import React from "react";
import { client1, client2, client3, client4, client5, client6 } from "../..";

function OurClients() {
  return (
    <section id="ourClients">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 ">
            <div className="clientWrap">
              <h3 className="sectionTitle">Some Brands We Work With</h3>
              <p>
                Hundreds of organizations, from the Fortune 100 to mid-sized
                enterprises, trust Vodote Techa to protect their business. See
                what sets us apart from the rest.
              </p>
              <div className="clientsLogoWrap d-none">
                <div className="custom_width_16">
                  <a
                    href="https://bit.ly/2ZUFYNf"
                    className="anchorDiv"
                    target="_blanks"
                  >
                    <img src={client1} alt="abstract" className="img-fluid" />
                  </a>
                </div>
                <div className="custom_width_16">
                  <a
                    href="https://bit.ly/2ZUFYNf"
                    className="anchorDiv"
                    target="_blanks"
                  >
                    <img src={client2} alt="cglobal" className="img-fluid" />
                  </a>
                </div>
                <div className="custom_width_16">
                  <a
                    href="https://bit.ly/2ZUFYNf"
                    className="anchorDiv"
                    target="_blanks"
                  >
                    <img src={client3} alt="next" className="img-fluid" />
                  </a>
                </div>
                <div className="custom_width_16">
                  <a
                    href="https://bit.ly/2ZUFYNf"
                    className="anchorDiv"
                    target="_blanks"
                  >
                    <img src={client4} alt="hemisferio" className="img-fluid" />
                  </a>
                </div>
                <div className="custom_width_16">
                  <a
                    href="https://bit.ly/2ZUFYNf"
                    className="anchorDiv"
                    target="_blanks"
                  >
                    <img src={client5} alt="spaces" className="img-fluid" />
                  </a>
                </div>
                <div className="custom_width_16">
                  <a
                    href="https://bit.ly/2ZUFYNf"
                    className="anchorDiv"
                    target="_blanks"
                  >
                    <img src={client6} alt="digitalbox" className="img-fluid" />
                  </a>
                </div>
              </div>
              <div className="client-logo-listing">
                <div className="brands-marquee">
                  <div className="js-marquee-wrapper">
                    <div
                      className="js-marquee"
                      style={{ marginRight: "0", float: "left" }}
                    >
                      <div
                        className="brands-marquee-inner"
                        style={{ marginRight: "0", float: "left" }}
                      >
                        <ul className="m-0">
                          <li>
                            <div className="  logo-brand-black-oracle svg_sprite_bg svg-sprite secondStripe"></div>
                          </li>
                          <li>
                            <div className="  logo-cambridge-v3 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  abdul-latif-jameel-logo svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  abdul-latif-jameel-logo-001 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-mtn svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-sanchobbdo svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-gulfstream svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-daman svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-astrazeneca svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-vfsglobal svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-haas svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-tata svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-yahoo svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-smithfield svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="logo-brand-black-best-buy svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-essilor svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-cosmopolitan svg_sprite_bg svg-sprite"></div>
                          </li>

                          <li>
                            <div className="  logo-brand-black-abbott svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-unsw svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-lifetechnologies svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-jacksoncoker svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-mcdonalds svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-vodafone svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-adidas svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-ndtv svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-kotak svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-macys svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-cartier svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo1 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo2 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo3 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo4 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo5 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo6 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo7 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo8 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo9 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo10 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo11 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo12 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo13 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo14 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo15 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo16 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo17 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo18 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo19 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo20 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo21 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo22 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo23 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo24 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo25 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo26 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo27 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo28 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo29 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo30 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo31 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo32 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo33 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo34 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo35 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo36 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo37 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo38 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo39 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo40 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo41 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo42 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo43 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo44 svg_sprite_bg svg-sprite"></div>
                          </li>
                          <li>
                            <div className="  logo-brand-black-Logo45 svg_sprite_bg svg-sprite"></div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurClients;
