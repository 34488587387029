import React from "react";
import BannerSection from "../components/banner/BannerSection";
import ConsultationWrap from "../components/homePageComponents/ConsultationWrap";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import StickyHeader from "../components/header/StickyHeader";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import WhyWorkWithUs from "../components/homePageComponents/whywork/WhyWorkWithUs";
import FeatureBlock from "../components/fetauresblock/FeatureBlock";
import AwardBox from "../components/awards/AwardBox";
import Abstract from "../components/homePageComponents/abstract/Abstract";
import SectionTitleRow from "../components/homePageComponents/security-resources/SectionTitleRow";
import AdviceCard from "../components/homePageComponents/security-resources/AdviceCard";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import { formImg } from "../components";

function HomePage() {
  return (
    <>
      <BannerSection />
      <ConsultationWrap />
      <OurClients />
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <OrganisationSection />
      <section className="darkBG">
        <WhyWorkWithUs />
        <div className="featureWrap">
          <div className="container">
            <FeatureBlock />
          </div>
        </div>
        <div className="awardSection">
          <div className="container">
            <h5>Awards</h5>
            <AwardBox />
          </div>
        </div>
      </section>
      <section className="abstractSection">
        <div className="container">
          <Abstract />
        </div>
      </section>
      <section className="darkBG">
        <div className="adviceSection">
          <div className="container">
            <SectionTitleRow />
            <AdviceCard />
          </div>
        </div>
      </section>
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </>
  );
}

export default HomePage;
