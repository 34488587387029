import React, { useState } from "react";
import FaqsAccordionitems from "./FaqsAccordionitems";

function FaqsAccordion({ items }) {
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (index === openItemIndex) {
      setOpenItemIndex(null);
    } else {
      setOpenItemIndex(index);
    }
  };
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <FaqsAccordionitems
          key={index}
          title={item.title}
          content={item.content}
          isOpen={index === openItemIndex}
          toggleAccordion={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
}

export default FaqsAccordion;
