import React from "react";
import SocialMediaFaqs from "./SocialMediaFaqs";
import { socialLeftImg } from "../..";

function SocialMediaSection() {
  return (
    <section className="socialMediaSection">
      <div className="container">
        <div className="sectionTitle">
          <h4>Harness The Power Of Social Media</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor
            placerat luctus.
          </p>
        </div>
        <div className="row justify-content-around align-items-center">
          <div className="col-md-5">
            <div className="socialMediaLeftBox">
              <img
                src={socialLeftImg}
                alt="Harness The Power Of Social Media"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-5">
            <SocialMediaFaqs />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SocialMediaSection;
