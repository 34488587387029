import React from "react";

function OurMethodology2() {
  return (
    <>
      <div className="row justify-content-between">
        <div className="col-md-6">
          <div className="methodparaWrap">
            <h5>Key Benefits</h5>
            <ul>
              <li>
                Our penetration tests adhere to industry security standards and
                best practices, including those established by OWASP (Open Web
                Application Security Project).
              </li>
              <li>
                We specialize in the manual penetration testing of web
                applications.
              </li>
              <li>
                We utilize cutting-edge commercial, open-source, and proprietary
                tools to enhance our services.
              </li>
              <li>
                We offer a comprehensive web application penetration service,
                serving as a one-stop solution. Our approach involves leveraging
                a team of experienced information security consultants who are
                adept at meeting tight schedules and budgets.
              </li>
              <li>
                Our approach to assessing the security of your web applications
                is non-disruptive and safe. We aim to understand hacker
                mentality and potential vulnerabilities without causing any
                disruptions to your business operations.
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-5">
          <div className="methodparaWrap">
            <h5>Our Methodology</h5>
            <ul>
              <li>Initial Consultations and Communications</li>
              <li>Information Gathering</li>
              <li>Risk Assessment</li>
              <li>Assessing Vulnerabilities</li>
              <li>Security Breach Attempt</li>
              <li>Actions After Successful Breach</li>
              <li>Documentation and Reporting</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurMethodology2;
