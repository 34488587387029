import React from "react";
import LeftBox from "../whywork/LeftBox";
import RightBox from "../whywork/RightBox";
import { testiMonial} from "../..";

function Abstract() {
  return (
    <div className="row">
      <RightBox img={testiMonial} />
      <LeftBox titleDescription="“Vodote cyber security services cover all the bases of my IT needs, from phishing and ransomware protection to user training. Maintaining IT systems that are not cumbersome. I love working with Vodote”" />
    </div>
  );
}

export default Abstract;
