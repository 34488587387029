import React from 'react';

function RightBox(props) {
  return (
    <div className='col-md-6'>
        <div className='rightInner'>
            <div className='imgWraps'>
                <img src={props.img} alt='' className='img-fluid' />
            </div>
        </div>
    </div>
  )
}

export default RightBox