import React from "react";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import StickyHeader from "../components/header/StickyHeader";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";
import QuestionAnswers from "../components/innerpagecomponents/QuestionAnswers";

function MobileApplicationSecurityTesting() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Security Service"
            bannerTitle="Mobile Application Security Testing"
            bannerPara="Mobile applications have become the latest trend in the tech industry."
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      <section className="conetentSection">
        <AboutPage
          line1="The tech industry is currently witnessing a significant surge in the popularity of mobile applications. If your business involves direct interactions with customers and you have an app, it's essential to recognize that, just like any other technology, mobile apps can be vulnerable to hacking if security weaknesses are present. To protect against these threats, you need a skilled team of security professionals who possess extensive knowledge of mobile application security."
          line2="At Vodote Techa, we specialize in meeting these requirements and ensuring the highest level of security for your app in all circumstances. As the leading mobile application security testing service in Mumbai, the growing number of satisfied clients we have served stands as proof of the outstanding quality of service we deliver."
          abImg={otherImg}
          altName="Mobile Application Security Testing"
        />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <section className="ourMethodologySection">
        <div className="container">
          <QuestionAnswers
            que1="What types of services are included within Mobile Application Security Testing?"
            ans11="Our comprehensive and meticulously researched mobile application security testing service incorporates all the essential elements necessary to fortify your mobile app's security. The smartphones or tablets utilized by your employees often store vast amounts of your company's sensitive information. Hackers may choose the path of least resistance by targeting your company's internal mobile app to access this data. Even if your company operates a consumer app with significant private information, it remains a prime target for hackers. As a responsible developer, ensuring its security is paramount."
            ans12="For your employees, we offer complete end-user device encryption, rendering it virtually impervious to hacker intrusion. Additionally, our services include features like remote device wiping, a valuable asset in the event of a lost employee device. Vodote Techa employs cutting-edge and regularly updated mobile antivirus software to safeguard your mobile application, providing protection against a wide array of malware threats."
            ans13="When it comes to your users, we guarantee that your application is resilient against the various security attacks commonly employed by hackers to breach applications. Furthermore, our proficiency extends to conducting vulnerability assessments and penetration testing, which pinpoint potential threats within your mobile application."
            que2="In what ways can Vodote Techa assist your enterprise?"
            ans21="When it comes to mobile application security testing, Vodote Techa offers a wide range of services designed to comprehensively protect your application. Many large organizations provide mobile devices to their employees, but these devices may not always align with the company's stringent security standards. We conduct a thorough assessment and provide detailed insights into your mobile application's overall security."
            ans22="Our process commences by identifying all the networks to which your company is connected. This initial step provides us with an overview of the total number of networks requiring our attention. By counting the number of SSIDs (Service Set Identifiers), we gain insight into the various networks utilized by your company."
            ans23="We understand the critical role that source code plays in securing an application, as it represents the foundation where vulnerabilities can potentially arise. By conducting a meticulous assessment and review of the source code, Vodote Techa generates a comprehensive report on the results of the mobile application security test."
            ans24="If you would like to learn more about our advanced security testing services, please don't hesitate to contact us at +91 7249643835 or send an email to info@vodote.com"
          />
        </div>
      </section>
      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default MobileApplicationSecurityTesting;
