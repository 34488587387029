import React from "react";

function TransparentBtn(props) {
  return (
    <a href={props.redirecLink} className={props.clsName}>
      <span className="btnText">{props.buttonTitle}</span>{" "}
      <span className="material-symbols-outlined">arrow_forward</span>
    </a>
  );
}

export default TransparentBtn;
