import React from "react";
import ContactForm from "../components/contactpagecomponents/ContactForm";
import { expertContact } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import FaqSection from "../components/faqs/FaqSection";

function ContactUs() {
  return (
    <>
      <section className="contactUsSection">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="sectionTitle">
                <h1>Get a personal consultation</h1>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-4">
                  <div className="contleftBox">
                    <div className="imgWrap">
                      <img
                        src={expertContact}
                        alt="Marketing Consultant Expert"
                        className="img-fluid rounded-4"
                      />
                    </div>
                    <div className="expertData">
                      <h4>Richard Madsen</h4>
                      <p>Marketing Consultant Expert</p>
                      <ul className="ulLsit">
                        <li>
                          <span>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                          Donec pretium augueultrices interdum
                        </li>
                        <li>
                          <span>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                          Nunc nulla ante, auctor non dolor vitae
                        </li>
                        <li>
                          <span>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                          Aliquam justo velit porttitor sodales
                        </li>
                      </ul>
                    </div>
                    <div className="expertQoute">
                      <p>
                        <em>
                          “The theme is always up to date with the changes of
                          wordpress, following everything that's happening.”
                        </em>
                      </p>
                      <h5>George Anderson</h5>
                      <span>Avada's CEO</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 pad_lr">
                  <div className="formWrap">
                    <div className="formInputWrap">
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FaqSection />
    </>
  );
}

export default ContactUs;
