import React from "react";
import { vaptimg } from "..";

function VAPTimg() {
  return (
    <section className="vaptimgSection">
      <div className="container">
        <div className="sectionTitle">
            <h4>Our VAPT Certification Methodology</h4>
        </div>
        <div className="col-md-12 col-12">
          <div className="vaptImgWraper">
            <img src={vaptimg} alt="Our VAPT Certification Methodology" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default VAPTimg;
