import React from "react";
import { methodImg } from "..";

function OurMethodology() {
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="methodImgWrap">
            <img src={methodImg} alt="Our Methodology"  className="img-fluid"/>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="methodparaWrap">
            <h5>Our Methodology</h5>
            <p>
              Vodote Techa’s information security assessment combines the best
              practices from various information security management standards.
              Our IS audit program integrates these standards with the expertise
              accumulated by our domain-specific teams over the years.
              Additionally, we follow a vulnerability assessment and penetration
              testing methodology that is both repeatable and well-documented.
              Our methodology remains up-to-date with changes in the threat
              environment and industry best practices, ensuring consistency and
              structure in information security audits and VAPT. Vodote Techa
              strives to stay one step ahead by continuously updating our IS
              program and techniques, incorporating new tools, processes, and
              industry trends. Our comprehensive methodology encompasses the
              following:
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="methodparaWrap">
            <ul>
              <li>ISO 27001:2013 Information Security Management Standard</li>
              <li>ISO 22301:2012 Business Continuity Management Standard</li>
              <li>PCI-DSS v3.2, PA-DSS</li>
              <li>
                NIST SP 800-115 Technical Guide to Information Security Testing
                and Assessment
              </li>
              <li>
                HIPAA (Health Protection & Prevention Act) and/or Data Privacy
                Law
              </li>
              <li>
                Open Source Security Testing Methodology Manual (OSSTMM) from
                the Institute for Security and Open Methodologies (ISECOM)
              </li>
              <li>SANS Security Controls</li>
            </ul>
            <p>
              Our IS audit covers various aspects, including network devices,
              network supporting services, application security, operating
              systems and databases hardening, process audits, non-technical
              controls audits, operational management reviews, BCP & DR reviews,
              compliance mappings, and capacity planning for new projects. We
              adopt a least-grant and need-to-know basis approach for network
              device audits.
            </p>
            <p>
              The IS audit assesses the state of security controls and their
              deployment on target systems. We check system “hardening”
              parameters against organizational guidelines, industry best
              practices, and recommendations. Our audits aim to uncover security
              vulnerabilities resulting from insecure vendor default settings,
              missing security patches, misconfigurations, and more. Vodote
              Techa utilizes a combination of tool-based and manual auditing
              techniques. We adhere to common industry best practices and
              recommendations, including:
            </p>
            <ul>
              <li>CIS Security Benchmarks</li>
              <li>DISA Security Guidelines</li>
              <li>CERT Guidelines</li>
              <li>SANS Security Guidelines</li>
              <li>NIST Security Guidelines</li>
              <li>Hardening Guidelines from Cisco, Oracle, and Microsoft.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurMethodology;
