import React from "react";
import FormSection from "../../banner/FormSection";

function FooterForm() {
  return (
    <>
      <FormSection />
    </>
  );
}

export default FooterForm;
