import React from "react";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import StickyHeader from "../components/header/StickyHeader";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";
import OurMethodology from "../components/innerpagecomponents/OurMethodology";

function InformationSecurityAuditService() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Audit Service"
            bannerTitle="Information Security Audit Service"
            bannerPara="When it comes to protecting your business data and information, compromising on security is not an option."
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      <section className="conetentSection">
        <AboutPage
          line1="In the digital age, safeguarding sensitive information has become crucial, as unscrupulous individuals exploit security loopholes for their own gain. To ensure your business remains at the forefront of security, a comprehensive and thorough security audit is essential. That’s where Vodote Techa, a professional IT security auditing service, comes in. Our team of experts excels in conducting IT security audits in Mumbai, serving a prestigious clientele."
          line2="When it comes to protecting your business data and information, compromising on security is not an option. Vodote Techa and similar IT security auditing companies provide detailed audits, presenting a comprehensive report that enables you to take immediate action. Additionally, we offer a complete range of security services to address all your needs, including VAPT services, web application security testing, and more."
          line3="At Vodote Techa, we employ the best methodologies to ensure the audit is conducted with the utmost accuracy, minimizing errors in the report."
          line4="Our cybersecurity audit identifies vulnerabilities within your system and sheds light on any existing security loopholes. With our highly skilled professionals, who possess extensive experience in the field, we conduct audits that surpass those performed by other security firms. Our exceptional team of security experts gives us a competitive edge."
          line5="Before commencing an IT security audit for your company, we establish a set of objectives that must be achieved. We maintain complete professionalism in all our work, adhering to the highest standards we have set for ourselves. Once the audit objectives are defined, we devote our efforts to fulfill them, ensuring that your expectations are met. This approach has positioned us as a leading IT security auditing company in India, earning the trust of renowned brands to safeguard their organizations. We continually strive to maintain the highest level of service quality."
          line6="Our audit encompasses the entire spectrum of security, covering network vulnerabilities, control issues, encryption-related problems, and more. It is our responsibility to provide a comprehensive audit report to enhance the security of your business. Vodote Techa stands as the premier security service provider in Mumbai, with an unmatched understanding of security. These factors provide you with every reason to trust our service quality and choose us for your company’s IT security audits."
          abImg={otherImg}
          altName="Information Security Audit Service"
        />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <section className="ourMethodologySection">
        <div className="container">
          <OurMethodology />
        </div>
      </section>
      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default InformationSecurityAuditService;
