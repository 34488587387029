import React from "react";
import { formImg } from "..";
import Form from "./Form";

function FormSection() {
  return (
    <div className="col-md-6 col-lg-5 pad_lr">
      <div className="formWrap">
        <div className="formHeaderSection">
          <div className="formImgWrap">
            <img src={formImg} alt="form img" className="img-fluid"/>
          </div>
          <div className="formData">
            <h5>Roger Mars</h5>
            <p>Cyber Expert Consultant</p>
            <a href="tel:+91 7249643835" className="btn">
              <span className="telPhoneIcon">
                <i className="fa fa-phone"></i>
              </span>
              +91 7249643835
            </a>
          </div>
        </div>
        <div className="formInputWrap">
            <Form/>
        </div>
      </div>
    </div>
  );
}

export default FormSection;
