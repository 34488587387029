import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import OrangeBtn from "../../cta/OrangeBtn";

function BannerLeftBox() {
  return (
    <div className="innerLeftWrap">
      <h1>We Want Your Business to Succeed</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor
        placerat luctus.
      </p>
      <div className="readMoreBtnWrap">
        <OrangeBtn
          redirecLink="###"
          clsName="btn w-45 greenBtn"
          btnName="Get A Consultation"
        />
      </div>
      <ul className="ulLsit">
        <li>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          Donec pretium augueultrices interdum
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          Nunc nulla ante, auctor non dolor vitae
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          Aliquam justo velit porttitor sodales
        </li>
      </ul>
      
    </div>
  );
}

export default BannerLeftBox;
