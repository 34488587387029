import React from "react";

function BannerFloatImg(props) {
  return (
    <div className="row">
      <div className="col-md-6 mx-auto">
        <div className="floatImgWrap">
          <img src={props.floatImg} alt="Votote Services" className="img-fluid"/>
        </div>
      </div>
    </div>
  );
}

export default BannerFloatImg;
