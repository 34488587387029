import React from "react";

function OrangeBtn(props) {
  return (
    <a href={props.redirecLink} className={props.clsName}>
      <i className="fa fa-phone"></i>
      <span>{props.btnName}</span>
    </a>
  );
}

export default OrangeBtn;
