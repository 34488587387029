import React from "react";
import { countImg1, countImg2, countImg3 } from "..";

const countData = [
  {
    id: 0,
    count_num: "245%",
    count_para: "Successful Rate",
    count_img: countImg1,
  },
  {
    id: 1,
    count_num: "#1",
    count_para: "Cyber Security Firm",
    count_img: countImg2,
  },
  {
    id: 2,
    count_num: "520+",
    count_para: "Secured Business",
    count_img: countImg3,
  },
];
function SuccessCountBox() {
  return (
    <div className="row countRow">
      {countData.map((items, index) => (
        <div className="col-md-4" key={index}>
          <div className="countBoxWrap">
            <div className="boxImgWrap">
              <img
                src={items.count_img}
                alt={items.count_para}
                className="img-fluid"
              />
            </div>
            <h3>{items.count_num}</h3>
            <p>{items.count_para}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SuccessCountBox;
