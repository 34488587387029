import React from "react";
import StrategyCard from "./StrategyCard";
import OrangeBtn from "../../cta/OrangeBtn";

function MarketingStrategySection() {
  return (
    <section className="strategySection">
      <div className="container">
        <div className="sectionTitle">
          <h4>Let's Work Together to Create Your Perfect Marketing Strategy</h4>
        </div>
        <div className="row justify-content-around">
          <StrategyCard />
        </div>
        <div className="readMoreBtnWrap d-flex justify-content-center">
          <OrangeBtn
            redirecLink="###"
            clsName="btn w-25 greenBtn"
            btnName="Get A Consultation"
          />
        </div>
      </div>
    </section>
  );
}

export default MarketingStrategySection;
