import React from "react";
import { ico1, ico2, ico3, ico4 } from "../..";

const cardData = [
  {
    id: 0,
    strg_name: "Increase Visibility",
    strg_para:
      "Suspendisse posuere condimentum dui, in ullamcorper leo iaculis non. Maeces aliquam accumsan mauris interdum.",
    strg_img: ico1,
  },
  {
    id: 1,
    strg_name: "Get Quality Leads",
    strg_para:
      "Suspendisse posuere condimentum dui, in ullamcorper leo iaculis non. Maeces aliquam accumsan mauris interdum.",
    strg_img: ico2,
  },
  {
    id: 2,
    strg_name: "Monthly Reporting",
    strg_para:
      "Suspendisse posuere condimentum dui, in ullamcorper leo iaculis non. Maeces aliquam accumsan mauris interdum.",
    strg_img: ico3,
  },
  {
    id: 3,
    strg_name: "Grow Your Customer Base",
    strg_para:
      "Suspendisse posuere condimentum dui, in ullamcorper leo iaculis non. Maeces aliquam accumsan mauris interdum.",
    strg_img: ico4,
  },
];
function StrategyCard() {
  return (
    <>
      {cardData.map((items, index) => (
        <div className="col-md-5" key={index}>
          <div className="strgWrap">
            <div className="iconBox col-2">
              <img
                src={items.strg_img}
                alt={items.strg_name}
                className="img-fluid"
              />
            </div>
            <div className="contentBox col-10">
              <h5>{items.strg_name}</h5>
              <p>{items.strg_para}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default StrategyCard;
