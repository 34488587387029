import React, { useRef, useState, useEffect } from "react";
import OrangeBtn from "../cta/OrangeBtn";

function StickyHeader(props) {
  const stickyHeaderRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const header = stickyHeaderRef.current;
      const headerHeight = header.getBoundingClientRect().height;
      const isStickyNow = window.scrollY > headerHeight;

      setIsSticky(isStickyNow);
      if (!isStickyNow) {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const headerClasses = isSticky ? "sticky" : "";
  return (
    <section id="stickyHeader" ref={stickyHeaderRef} className={headerClasses}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-11 mx-auto">
            <div className="stickyBoxWrap">
              <div className="custom_width_8">
                <div className="profileImgWrap">
                  <img
                    src={props.profileImg}
                    alt={props.designation}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="custom_width_20">
                <div className="profileDataWrap">
                  <h6>{props.name}</h6>
                  <p>{props.designation}</p>
                </div>
              </div>
              <div className="custom_width_46">
                <div className="barTitle">
                  <h5>{props.title}</h5>
                </div>
              </div>
              <div className="custom_width_26">
                <div className="barBtn">
                  <OrangeBtn
                    clsName="btn orgBtn"
                    btnName="+91 7249643835"
                    redirecLink="tel:+91 7249643835"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StickyHeader;
