import React from "react";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import StickyHeader from "../components/header/StickyHeader";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";
import OurMethodology2 from "../components/innerpagecomponents/OurMethodology2";
import VAPTimg from "../components/innerpagecomponents/VAPTimg";

function WebApplicationPenetrationTesting() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Audit Service"
            bannerTitle="Web Application Penetration Testing"
            bannerPara="Strengthening digital fortresses through Web Application Penetration Testing."
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      <section className="conetentSection">
        <AboutPage
          line1="The proliferation of web applications is occurring at an extraordinary pace, raising heightened concerns regarding their security. Hackers are becoming increasingly sophisticated and aggressive in exploiting vulnerabilities within web applications to access desired data. Consequently, it is crucial to engage a team of specialists to regularly perform web application security testing. Vodote Techa, a leading security services provider, offers premier web application security testing services in Mumbai."
          line2="We perform comprehensive testing for even the most intricate web applications and address any existing vulnerabilities to prevent malicious or criminal exploitation. To serve this purpose effectively, we are renowned for offering Vulnerability Assessment and Penetration Testing (VAPT) services in Mumbai. In the realm of security, we stand at the forefront among other service providers. Our methodologies align with industry standards, and we leave no stone unturned in our endeavor to identify vulnerabilities within your web applications."
          line3="Many businesses often lack awareness of the security status of their web applications. They typically hire web developers to create their websites and operate with minimal customizations. Unfortunately, this approach can become a nightmare for businesses holding sensitive data, as it presents potential vulnerabilities that malicious hackers may exploit."
          line4="To safeguard against such threats, Vodote Techa conducts a comprehensive assessment of all potential security risks associated with web applications. Additionally, we offer Vulnerability Assessment and Penetration Testing (VAPT) services in India, ensuring that no company falls victim to the malicious intentions of hackers."
          line5="Numerous businesses rely heavily on highly confidential and sensitive data that cannot afford any compromise. Our team comprises exceptionally qualified experts with an intricate understanding of security. They possess extensive experience in identifying vulnerabilities and stay current with the latest security trends in the IT sector. When it comes to VAPT services in Mumbai, you won't find better companies that conduct tests using highly effective methodologies to ensure the security of your business."
          line6="In addition to identifying the most prevalent security flaws, we also specialize in discovering and addressing less obvious vulnerabilities. As the saying goes, prevention is always better than cure. To ensure the safety of your confidential information, entrust us with the responsibility of securing your web applications."
          abImg={otherImg}
          altName="Web Application Penetration Testing"
        />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <section className="ourMethodologySection">
        <div className="container">
          <OurMethodology2 />
        </div>
      </section>
      <VAPTimg />
      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default WebApplicationPenetrationTesting;
