import React from "react";
import Lottie from "react-lottie";

function LeftAnimatorBox(props) {
  return (
    <div className="left_anime_box">
      <Lottie options={props.options} className="animeGIf" />
    </div>
  );
}

export default LeftAnimatorBox;
