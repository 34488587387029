import React from "react";
import SecurityCard from "../SecurityCard";

function WebsiteSecuritySection(props) {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h3> {props.title}</h3>
        <p>{props.para}</p>
      </div>
      <div className="row">
        <SecurityCard />
      </div>
    </div>
  );
}

export default WebsiteSecuritySection;
