import React from "react";
import BannerLeftBox from "../bannercomponent/BannerLeftBox";
import BannerRightBox from "../bannercomponent/BannerRightBox";

function WhyUsBanner() {
  return (
    <section className="bannerSection">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-5">
            <BannerLeftBox />
          </div>
          <div className="col-md-7">
            <BannerRightBox />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUsBanner;
