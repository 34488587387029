import React from "react";
import { whypageBanner } from "../..";

function BannerRightBox() {
  return (
    <div className="innerRightWrap">
      <img src={whypageBanner} alt="Vodote Techa" className="img-fluid" />
      <div className="overlayTextWrap">
        <h5>HEMISFERIO</h5>
        <p>Our website revenue has increased by over 210% in only 9 months.</p>
        <h6>Richard Taylor</h6>
        <span>Founder & CEO of Hermisferio</span>
      </div>
    </div>
  );
}

export default BannerRightBox;
