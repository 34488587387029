import React from "react";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import StickyHeader from "../components/header/StickyHeader";
import QuestionAnswers from "../components/innerpagecomponents/QuestionAnswers";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";

function WirelessAuditing() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Security Service"
            bannerTitle="Wireless Auditing"
            bannerPara="With wireless networks being entirely wireless, the potential for hackers to exploit them has increased."
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      <section className="conetentSection">
        <AboutPage
          line1="The proliferation of wireless networks has heightened security threats significantly. With wireless networks being entirely wireless, the potential for hackers to exploit them has increased. Many of the wireless networks currently in use lack robust and secure native protocols. Additionally, the encryption designs often exhibit vulnerabilities that even an amateur hacker can exploit using simple techniques."
          line2="To address these security challenges and ensure the safety of your wireless networks, Vodote Techa offers a tailored service known as wireless auditing. This service is specifically designed for corporations that maintain a large number of poorly configured wireless networks. Without proper security measures, every wireless network connection is susceptible to compromise. With Vodote Techa's top-notch wireless auditing services, you can eliminate this concern."
          abImg={otherImg}
          altName="Wireless Auditing"
        />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <section className="ourMethodologySection">
        <div className="container">
          <QuestionAnswers
            que1="Key Advantages of Conducting Wireless Audits"
            ans11="Security is undeniably a primary concern for all companies, regardless of their size. Nobody wants to risk exposing their confidential information to hackers with malicious intent. Conducting a wireless audit through a reputable security firm in Mumbai, such as Vodote Techa, offers multiple advantages."
            ans12="In some cases, the configuration of your WLAN (Wireless Local Area Network) may contain vulnerabilities that could provide hackers with access to your network. A wireless audit is invaluable for identifying and rectifying such weak configurations. Furthermore, the audit empowers you to exercise better control over your entire wireless network. With thorough reporting and auditing in place, your employees can enjoy increased productivity, as they no longer need to be concerned about the wireless network's security. Vodote Techa ensures the comprehensive efficiency of the wireless network audit, covering all related aspects."
            
            que2="How Vodote Techa Approaches Wireless Auditing?"
            ans21="Vodote Techa employs a highly professional approach when conducting wireless audits for each company. With the assistance of our exceptional team, we execute a meticulous auditing process, employing best-in-class techniques to uncover even the most critical vulnerabilities present in your wireless networks. Our process commences by identifying all the networks to which your company is connected. This initial step provides us with an overview of the total number of networks requiring our attention. By counting the number of SSIDs (Service Set Identifiers), we gain insight into the various networks utilized by your company."
            ans22="Each network employs a specific security algorithm, which can be WEP, WPA, WPA2, LEAP, or others. We will employ diverse methods to test the resilience of these algorithms and determine whether they are vulnerable to various forms of attacks. Subsequently, we will conduct multiple attack scenarios to assess the overall security level of your wireless networks."
            ans23="Through these attack simulations, we aim to gain access to the network through penetration testing. Upon completion, we will compile a comprehensive report detailing the vulnerabilities and weaknesses present in your wireless network. Additionally, we will provide you with precise recommendations and guidelines to fortify your network, ensuring that it remains impervious to potential hacker intrusions."
            ans24="Vodote Techa utilizes top-of-the-line wireless audit tools to perform comprehensive auditing and furnish you with a report that will bolster the security of all your wireless networks across your organization. To learn more about this service, please contact us at +91 7249643835 or send an email to info@vodote.com."
          />
        </div>
      </section>
      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default WirelessAuditing;
