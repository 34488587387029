import React from "react";
import FaqsAccordion from "../../faqs/FaqsAccordion";

function SocialMediaFaqs() {
  const accordionItems = [
    {
      title:
        "Customer Service",
      content:
        "Curabitur ac leo nunc. Vestibulum et mauris vel ante finibus maximus nec ut leo. Integer consectetur luctus dui id convallis. Sed pellentesque erat non eu auctor tortor, sit amet egestas purus. Sed tincidunt tempor leo, vitae consequat lorem ornare ultricies. Nullam fringilla ultricies pharetra.",
    },
    {
      title:
        "Online Consultation",
      content:
        "Curabitur ac leo nunc. Vestibulum et mauris vel ante finibus maximus nec ut leo. Integer consectetur luctus dui id convallis. Sed pellentesque erat non eu auctor tortor, sit amet egestas purus.",
    },
    {
      title: "Sales Management",
      content:
        "Curabitur ac leo nunc. Vestibulum et mauris vel ante finibus maximus nec ut leo. Integer consectetur luctus dui id convallis. Sed pellentesque erat non eu auctor tortor, sit amet egestas purus.",
    },
  ];
  return (
    <div className="accordionWrap">
      <FaqsAccordion items={accordionItems} />
    </div>
  );
}

export default SocialMediaFaqs;
