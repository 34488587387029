import React from "react";
import LeftBox from "./LeftBox";
import RightBox from "./RightBox";
import { whyus } from "../..";

function WhyWorkWithUs() {
  return (
    <div className="container">
      <div className="row">
        <LeftBox
          title="Cybersecurity Operations That Protect Your Business"
          titleDescription="Our Managed Detection and Response solutions help with your most
          pressing challenges."
        />
        <RightBox img={whyus}/>
      </div>
    </div>
  );
}

export default WhyWorkWithUs;
