import React from "react";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import StickyHeader from "../components/header/StickyHeader";
import QuestionAnswers from "../components/innerpagecomponents/QuestionAnswers";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";

function WebsiteSecurityManagement() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Security Service"
            bannerTitle="Website Security Management"
            bannerPara="In the rapidly evolving landscape of technology, businesses often find themselves continuously engaged without taking the necessary pause to prioritize their company's security."
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      <section className="conetentSection">
        <AboutPage
          line1="Failing to address this issue is among the most substantial mistakes an organization can commit, which could potentially result in extensive data breaches and other security issues. What's not widely recognized is that companies have the opportunity to delegate all their security requirements to a specialized company staffed with dedicated professionals who meticulously monitor their security status."
          line2="This all-inclusive offering is frequently termed the Annual Website Security Management service. If you're in search of a reputable and long-standing security ally to oversee these crucial aspects for your business, Vodote Techa emerges as an exceptionally advantageous selection."
          abImg={otherImg}
          altName="Website Security Management"
        />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <section className="ourMethodologySection">
        <div className="container">
          <QuestionAnswers
            que1="What Makes Vodote Techa the Optimal Selection for this Service?"
            ans11="We provide an extensive range of security services in Mumbai, and regardless of your company's location, our team is equipped to serve businesses of all sizes. Without regular security audits, it's challenging to guarantee complete safety for your organization. That's why we offer meticulously planned security audit reports at regular intervals, allowing you to focus solely on your work without the added worry. Should a threat arise, our team promptly takes action to address it, ensuring no data or files are compromised."
            que2="Which services are encompassed within the Annual Website Security Management package?"
            ans21="Our Annual Website Security Management service offers a wide range of services provided by our expert security team, who are committed to continually improving your organization's information technology security. These services encompass employee training to bolster their security practices, ongoing malware monitoring to detect and address potential threats, in-depth reviews of your web and mobile applications to mitigate vulnerabilities, annual assessments of your servers and web-based applications to ensure compliance with security standards, and comprehensive vulnerability assessment and penetration testing. As a leading provider of these security measures in Mumbai, our package delivers all of these crucial services to enhance your security posture."
            ans22="Our generated reports are tailor-made specifically for your business, offering a comprehensive overview of your firm's security status at a glance. These reports are designed with readability in mind, providing in-depth coverage of all essential aspects necessary for a fully secure environment. Additionally, we offer multiple revisions and solutions for the same issue, allowing you to select the one that aligns best with your preferences. These services, along with many others, are encompassed within our offering. For further information, please don't hesitate to reach out to us at +91 7249643835 or send an email to info@vodote.com."
           
          />
        </div>
      </section>
      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default WebsiteSecurityManagement;
