import React, { useEffect, useState } from "react";
import OrganisationCard from "./OrganisationCard";
import OrangeBtn from "../../cta/OrangeBtn";
import { useLocation } from "react-router-dom";

function OrganisationSection() {
  const location = useLocation();
  const { pathname } = location;
  const [isDisplay, setIsDisplay] = useState(false);

  useEffect(() => {
    if (pathname === "/") {
      setIsDisplay(true);
    } else if (pathname === "/services/vulnerability-assessment-service") {
      setIsDisplay(false);
    }else if (pathname === "/why-us") {
      setIsDisplay(false);
    } else {
      setIsDisplay(true);
    }
  }, [pathname]);

  return (
    <section id="organisationSection">
      <div className="container">
        {isDisplay ? (
          <div className="sectionTitle">
            <h3>Trusted by Leading Organisations</h3>
          </div>
        ) : (
          <div className="sectionTitle orgOtherPage">
            <div className="row">
              <div className="col-md-6">
                <h3>Learn How We Have Helped Leading Organizations</h3>
              </div>
              <div className="col-md-3 d-flex justify-content-end align-items-end">
                <div className="readMoreBtnWrap">
                  <OrangeBtn
                    redirecLink="##"
                    clsName="btn greenBtn"
                    btnName="Read More Case Studies"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <OrganisationCard />
        <div className="readMoreBtnWrap">
          <OrangeBtn
            redirecLink="###"
            clsName="btn greenBtn"
            btnName="Read More Case Studies"
          />
        </div>
      </div>
    </section>
  );
}

export default OrganisationSection;
