import React from "react";

function AboutPage(props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="abtContentWrap">
            <p>
              {props.line1}
            </p>
            <p>
              {props.line2}
            </p>
            <p>
              {props.line3}
            </p>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="abtPageImgWrap">
            <img src={props.abImg} alt={props.altName} className="img-fluid"/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="abtContentWrap">
            <p>
              {props.line4}
            </p>
            <p>
            {props.line5}
            </p>
            <p>
            {props.line6}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
