import React, { useState } from "react";
import GreenBtn from "../cta/GreenBtn";

function Form() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="col">
        <input
          className="inputBox"
          type="text"
          id="name"
          name="name"
          placeholder="Your Name*"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="col">
        <input
          className="inputBox"
          type="email"
          id="email"
          name="email"
          placeholder="Your Email*"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="col">
        <input
          className="inputBox"
          type="tel"
          id="mobile"
          name="mobile"
          placeholder="Your Phone Number*"
          value={formData.mobile}
          onChange={handleInputChange}
          required
        />
      </div>
      <GreenBtn greenBtnText="Get A Callback" />
      <p className="formText">By submitting my data I agree to be contacted</p>
    </form>
  );
}

export default Form;
