import React from "react";
import OrangeBtn from "../../cta/OrangeBtn";

function SectionTitleRow() {
  return (
    <div className="row titleRow">
      <div className="col-md-6">
        <div className="sectionTitle">
          <h5>
            Security Resources: Insider Advice on How to Protect Your Business
            Online
          </h5>
        </div>
      </div>
      <div className="col-md-3">
        <div className="btnBox">
          <OrangeBtn
            redirecLink="#"
            clsName="btn greenBtn"
            btnName="Explore All Resources"
          />
        </div>
      </div>
    </div>
  );
}

export default SectionTitleRow;
