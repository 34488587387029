import React from "react";
import OrangeBtn from "../cta/OrangeBtn";

function BannertextBox(props) {
  return (
    <div className="row">
      <div className="col-md-10 col-lg-8 mx-auto">
        <div className="bannerTextWrap">
          <span>{props.span}</span>
          <h1>{props.bannerTitle}</h1>
          <p>{props.bannerPara}</p>
        </div>
        <div className="getConsultBtnWrap">
          <OrangeBtn
            redirecLink="##"
            clsName="btn consultBtn"
            btnName="Get A Consultation"
          />
        </div>
      </div>
    </div>
  );
}

export default BannertextBox;
