import React from "react";
import { award1, award2, award3, award4 } from "..";
const awardData = [
  {
    id: 0,
    ids: 'img1',
    award_img: award1,
    award_title1: "Winner Seo Master MAGT",
    award_title2: "Smart Start Award 2017",
  },
  {
    id: 1,
    ids: 'img2',
    award_img: award2,
    award_title1: "Top Social Media Agencies",
    award_title2: "Next Partner 2018",
  },
  {
    id: 2,
    ids: 'img3',
    award_img: award3,
    award_title1: "10 Fastest Growing Abstract",
    award_title2: "Solution Providers 2019",
  },
  {
    id: 3,
    ids: 'img4',
    award_img: award4,
    award_title1: "National Excellence Agencie",
    award_title2: "Award Winner 2020",
  },
];
function AwardBox() {
  return (
    <div className="row">
      {awardData.map((items, index) => (
        <div className="col-md-3" key={index}>
          <div className="awardBox">
            <div className="awardImgWrap">
              <img src={items.award_img} alt={items.award_title1} className="img-fluid" id={items.ids}/>
            </div>
            <div className="awardNameWrap">
              <p>{items.award_title1}</p>
              <p>{items.award_title2}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AwardBox;
