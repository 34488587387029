import React from "react";
import OrangeBtn from "../../cta/OrangeBtn";

function LeftBox(props) {
  return (
    <div className="col-md-6">
      <div className="leftInner">
        <h3 className="sectionTitle">{props.title}</h3>
        <p className="sectionPara">{props.titleDescription}</p>
        <div className="abstractorName">
          <h5>Kate Schadler</h5>
          <p>Marketing Consultant – Abstract</p>
        </div>
        <div className="btnWrap">
          <OrangeBtn
            redirecLink="###"
            clsName="btn greenBtn"
            btnName="Why Work With Us"
          />
        </div>
      </div>
    </div>
  );
}

export default LeftBox;
