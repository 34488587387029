import React from "react";
import { adviceImg1, adviceImg2, adviceImg3 } from "../..";
const adviceCard = [
  {
    id: 0,
    card_img: adviceImg1,
    card_title: "Marketing Tips And Tricks For Your Website",
    card_link: "https://vodote.com/marketing-tips-and-tricks-for-your-website/",
  },
  {
    id: 1,
    card_img: adviceImg2,
    card_title: "How to Write Stunning Blog Post Titles",
    card_link: "https://vodote.com/how-to-write-stunning-blog-post-titles/",
  },
  {
    id: 2,
    card_img: adviceImg3,
    card_title: "Techniques to Reduce Facebook Ads Spend",
    card_link: "https://vodote.com/techniques-to-reduce-facebook-ads-spend/",
  },
];
function AdviceCard() {
  return (
    <div className="adviceCardsSection">
      <div className="container">
        <div className="row">
          {adviceCard.map((item, index) => (
            <div className="outerCardWrap col-md-4" key={index}>
              <div className="innerCardWrap">
                <div className="cardImageWrap">
                  <a href={item.card_link} target="_blan">
                    <img
                      src={item.card_img}
                      alt={item.card_title}
                      className="img-fluid"
                    />
                    <div className="overlay"></div>
                  </a>
                </div>
                <div className="cardTitleWrap">
                  <a href={item.card_link} target="_blan">
                    <h3>{item.card_title}</h3>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdviceCard;
