import React from "react";

function FaqsAccordionitems({ title, content, isOpen, toggleAccordion }) {
  return (
    <div className={isOpen ? "accordion-item open" : "accordion-item "}>
      <div className="accordion-title" onClick={toggleAccordion}>
        {title}
        <span className={`icon ${isOpen ? "minus" : "plus"}`}>
          {isOpen ? (<i className="fa fa-minus"></i>) : (<i className="fa fa-plus"></i>)}
        </span>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
}

export default FaqsAccordionitems;
