import React from "react";

function QuestionAnswers(props) {
  return (
    <>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="methodparaWrap">
            <h5>
              {props.que1}
            </h5>
            <p>
              {props.ans11}
            </p>
            <p>
              {props.ans12}
            </p>
            <p>
              {props.ans13}
            </p>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-md-12 mx-auto">
          <div className="methodparaWrap">
            <h5>{props.que2}</h5>
            <p>
              {props.ans21}
            </p>
            <p>
              {props.ans22}
            </p>
            <p>
              {props.ans23}
            </p>
            <p>
              {props.ans24}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionAnswers;
