import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="textRow">
              <h4>
                Grow Your Online Business Strategically, and Improve Customer
                Retention.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <section className="btmFooter">
        <div className="copyRightRow">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="copyRightBox">
                  <p>© 2020 • Vodote Techa</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="copyRightBox">
                  <p>Call Us +91 7249643835</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="social_row">
                  <ul>
                    <li>
                      <a href="##">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="##">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="22px"
                          clipRule="evenodd"
                          baseProfile="basic"
                        >
                          <polygon
                            fill="#fff"
                            points="41,6 9.929,42 6.215,42 37.287,6"
                          />
                          <polygon
                            fill="#fff"
                            fillRule="evenodd"
                            points="31.143,41 7.82,7 16.777,7 40.1,41"
                            clipRule="evenodd"
                          />
                          <path
                            fill="#fff"
                            d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="##">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="##">
                        <i className="fa fa-youtube-play"></i>
                      </a>
                    </li>
                    <li>
                      <a href="##">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
