import React from "react";
import ChartCard from "./ChartCard";

function ChartSection() {
  return (
    <section className="chartSection">
      <div className="container">
        <ChartCard />
      </div>
    </section>
  );
}

export default ChartSection;
