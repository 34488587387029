import React, { useEffect, useState } from "react";
import { cardImg1, cardImg2, cardImg3 } from ".";
import OrangeBtn from "./cta/OrangeBtn";
import { useLocation } from "react-router-dom";
const cardData = [
  {
    id: 0,
    card_title: "Cyber Threats",
    card_description:
      "By implementing robust access control mechanisms, you can ensure that only authorized individuals have the necessary permissions to access sensitive areas of your website.",
    card_img: cardImg1,
    card_link: "#aaa",
  },
  {
    id: 1,
    card_title: "Vulnerability Assessments",
    card_description:
      "Conducting regular vulnerability assessments and implementing a robust patch management process are vital components of effective website security management.",
    card_img: cardImg2,
    card_link: "#bbb",
  },
  {
    id: 2,
    card_title: "Continuous Monitoring",
    card_description:
      "Implementing robust security monitoring tools and systems allows for real-time threat detection, alerting you to any suspicious activities or potential security breaches.",
    card_img: cardImg3,
    card_link: "#ccc",
  },
];
const cardData2 = [
  {
    id: 0,
    card_title: "Advertising",
    card_description:
      "Curabitur ac leo nunc. Vestibulum et mauris vel ante finibus.",
    card_img: cardImg1,
    card_link: "#aaa",
  },
  {
    id: 1,
    card_title: "Performance",
    card_description:
      "Curabitur ac leo nunc. Vestibulum et mauris vel ante finibus.",
    card_img: cardImg2,
    card_link: "#bbb",
  },
  {
    id: 2,
    card_title: "Optimization",
    card_description:
      "Curabitur ac leo nunc. Vestibulum et mauris vel ante finibus.",
    card_img: cardImg3,
    card_link: "#ccc",
  },
];
function SecurityCard() {
  const location = useLocation();
  const { pathname } = location;
  const [isDisplay, setIsDisplay] = useState(false);
  useEffect(() => {
    if (pathname === "/why-us") {
      setIsDisplay(false);
    } else if (pathname === "/services/information-security-audit-service") {
      setIsDisplay(true);
    } else if (pathname === "/services/web-application-penetration-testing") {
      setIsDisplay(true);
    } else if (pathname === "/services/mobile-application-security-testing") {
      setIsDisplay(true);
    } else if (pathname === "/services/web-application-security") {
      setIsDisplay(true);
    } else if (pathname === "/services/penetration-testing-service") {
      setIsDisplay(true);
    } else if (pathname === "/services/wireless-auditing") {
      setIsDisplay(true);
    } else if (pathname === "/services/website-security-management") {
      setIsDisplay(true);
    } else if (pathname === "/services/spear-phishing") {
      setIsDisplay(true);
    } else {
      setIsDisplay(true);
    }
  }, [pathname]);
  return (
    <>
      {isDisplay
        ? cardData.map((item, index) => (
            <div className="col-md-6 col-lg-4 webSecureCard" key={index}>
              <div className="webSecureCardWrap">
                <h5>{item.card_title}</h5>
                <p>{item.card_description}</p>
                <div className="cardImgWrap">
                  <img
                    src={item.card_img}
                    alt={item.card_title}
                    className="img-fluid"
                  />
                </div>
                <div className="getQuote w-75 mx-auto">
                  <OrangeBtn
                    redirecLink={item.card_link}
                    clsName="btn orgBtn"
                    btnName="Learn More"
                  />
                </div>
              </div>
            </div>
          ))
        : cardData2.map((item, index) => (
            <div className="col-md-4 webSecureCard" key={index}>
              <div className="webSecureCardWrap">
                <h5>{item.card_title}</h5>
                <p>{item.card_description}</p>
                <div className="cardImgWrap">
                  <img
                    src={item.card_img}
                    alt={item.card_title}
                    className="img-fluid"
                  />
                </div>
                <div className="getQuote w-75 mx-auto">
                  <OrangeBtn
                    redirecLink={item.card_link}
                    clsName="btn orgBtn"
                    btnName="Learn More"
                  />
                </div>
              </div>
            </div>
          ))}
    </>
  );
}

export default SecurityCard;
