import React from "react";
import { chartImg1, chartImg2 } from "..";
const chartData = [
  {
    id: 0,
    card_name: "Grow your traffic",
    card_para:
      "Nullam et cursus neque, eget fringilla dolor, curabitur ac leo nunc. Vestibulum et mauris vel ante finibus.",
    card_img: chartImg1,
  },
  {
    id: 1,
    card_name: "Increase your sales",
    card_para:
      "Nullam et cursus neque, eget fringilla dolor, curabitur ac leo nunc. Vestibulum et mauris vel ante finibus.",
    card_img: chartImg2,
  },
];
function ChartCard() {
  return (
    <div className="row chartRow">
      {chartData.map((items, index) => (
        <div className="col-md-6 col-lg-5" key={index}>
          <div className="chartCardWrap">
            <h4>{items.card_name}</h4>
            <p>{items.card_para}</p>
            <div className="chartImgWrap">
              <img
                src={items.card_img}
                alt={items.card_name}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ChartCard;
