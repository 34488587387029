import React from "react";
import { client1, formImg } from "..";

function AbstractTestimonial() {
  return (
    <section className="absTestimonialSection">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="abstestiWrap">
              <img src={client1} alt="Abstract" className="img-fluid" />
              <h5>
                “The team at Avada Marketing Consultant is fabulous. They helped
                us unlock our potential online and offline. We have experienced
                year on year growth due to their progressive approach.”
              </h5>
              <div className="profileImgWrap">
                <img src={formImg} alt="Abstract" className="img-fluid" />
              </div>
              <h6>Melissa Dean</h6>
              <span>Marketing Consultant Expert</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AbstractTestimonial;
