import React, { useState } from "react";
// import GreenBtn from "../cta/GreenBtn";

function ContactForm() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    chalenge: "",
    check: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit} action="" method="post">
      <div className="col">
        <label htmlFor="first_name">
          First Name{" "}
          <abbr className="fusion-form-element-required" title="required">
            *
          </abbr>
        </label>
        <input
          className="inputBox"
          type="text"
          id="first_name"
          name="first_name"
          placeholder="Your First Name*"
          value={formData.first_name}
          onChange={handleInputChange}
        />
      </div>
      <div className="col">
        <label htmlFor="last_name">
          Last Name{" "}
          <abbr className="fusion-form-element-required" title="required">
            *
          </abbr>
        </label>
        <input
          className="inputBox"
          type="text"
          id="last_name"
          name="last_name"
          placeholder="Your Last Name*"
          value={formData.last_name}
          onChange={handleInputChange}
        />
      </div>
      <div className="col">
        <label htmlFor="last_name">
          Email{" "}
          <abbr className="fusion-form-element-required" title="required">
            *
          </abbr>
        </label>
        <input
          className="inputBox"
          type="email"
          id="email"
          name="email"
          placeholder="Your Email*"
          value={formData.email}
          onChange={handleInputChange}
        />
      </div>
      <div className="col">
        <label htmlFor="last_name">
          Phone Number{" "}
          <abbr className="fusion-form-element-required" title="required">
            *
          </abbr>
        </label>
        <input
          className="inputBox"
          type="tel"
          id="mobile"
          name="mobile"
          placeholder="e.g. (555) 802-1234*"
          value={formData.mobile}
          onChange={handleInputChange}
        />
      </div>
      <div className="col position-relative">
        <label htmlFor="selection">
          Service Needed{" "}
          <abbr className="fusion-form-element-required" title="required">
            *
          </abbr>
          <span>
            <i className="fa fa-question-circle"></i>
            <div className="tooltipBox">
              <span>Curabitur elementum cursus nulla.</span>
            </div>
          </span>
        </label>

        <select className="select">
          <option value="Marketing Consultancy">Marketing Consultancy</option>
          <option value="Digital Marketing">Digital Marketing</option>
          <option value="Lead Generation">Lead Generation</option>
          <option value="Brand Strategy">Brand Strategy</option>
          <option value="Web Development">Web Development</option>
        </select>
        <span className="material-symbols-outlined downArrow">expand_more</span>
      </div>
      <div className="col">
        <label htmlFor="chalenge">Your Challenge</label>
        <textarea
          className="inputBox"
          type="text"
          id="chalenge"
          name="chalenge"
          placeholder="I need help with the next problem..."
          value={formData.chalenge}
          onChange={handleInputChange}
        />
      </div>
      <div className="col">
        <div className="form-check align-items-center d-flex">
          <input
            className="form-check-input custom-checkbox"
            type="checkbox"
            value={formData.check}
            name="check"
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Receive Marketing Resources
          </label>
        </div>
      </div>
      <button type="submit" className="btn w-100 formBtn">
        Subscribe
      </button>
    </form>
  );
}

export default ContactForm;
