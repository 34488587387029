import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import NavigationBar from "./components/header/NavigationBar";
import HomePage from "./pages/HomePage";
import VulnerabilityAssessmentService from "./pages/VulnerabilityAssessmentService";
import InformationSecurityAuditService from "./pages/InformationSecurityAuditService";
import WebApplicationPenetrationTesting from "./pages/WebApplicationPenetrationTesting";
import MobileApplicationSecurityTesting from "./pages/MobileApplicationSecurityTesting";
import WebApplicationSecurity from "./pages/WebApplicationSecurity";
import WebsiteSecurityManagement from "./pages/WebsiteSecurityManagement";
import PenetrationTestingService from "./pages/PenetrationTestingService";
import WirelessAuditing from "./pages/WirelessAuditing";
import SpearPhishing from "./pages/SpearPhishing";
import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";
import ContactUs from "./pages/ContactUs";
import WhyUs from "./pages/WhyUs";
import CaseStudies from "./pages/CaseStudies";
import About from "./pages/About";
import Blog from "./pages/Blog";
function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 100,
    });
  }, []);
  // const { slug } = useParams();
  // console.log(slug);
  return (
    <BrowserRouter forceRefresh={true}>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/services/vulnerability-assessment-service"
          element={<VulnerabilityAssessmentService />}
        />
        <Route
          path="/services/information-security-audit-service"
          element={<InformationSecurityAuditService />}
        />
        <Route
          path="/services/web-application-penetration-testing"
          element={<WebApplicationPenetrationTesting />}
        />
        <Route
          path="/services/mobile-application-security-testing"
          element={<MobileApplicationSecurityTesting />}
        />
        <Route
          path="/services/web-application-security"
          element={<WebApplicationSecurity />}
        />
        <Route
          path="/services/website-security-management"
          element={<WebsiteSecurityManagement />}
        />
        <Route
          path="/services/penetration-testing-service"
          element={<PenetrationTestingService />}
        />
        <Route
          path="/services/wireless-auditing"
          element={<WirelessAuditing />}
        />
        <Route path="/services/spear-phishing" element={<SpearPhishing />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
