import React from "react";
import TransparentBtn from "../cta/TransparentBtn";
import FormSection from "./FormSection";

function BannerSection() {
  return (
    <section id="banner_section">
      <div className="container">
        <div className="row formRow">
          <div className="col-md-6 p-0" data-aos="fade-right">
            <h1 className="sectionTitle">
              We{" "}
              <span className="svgWrap">
                <span className="svgBox">Secure</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 150"
                  preserveAspectRatio="none"
                >
                  <path d="M8.1,146.2c0,0,240.6-55.6,479-13.8"></path>
                </svg>
              </span>{" "}
              Your Business
            </h1>
            <h3 className="sectionPara">
              At Vodote Techa Solutions we believe in developing
              forward-thinking technologies to secure your business. After all,
              to beat the evolving threats we must evolve our solutions.
            </h3>
            <div className="ctaWrap">
              <TransparentBtn
                buttonTitle="Our Services"
                clsName="transbtn btn"
                redirecLink="#"
              />
            </div>
          </div>
          <FormSection />
        </div>
      </div>
    </section>
  );
}

export default BannerSection;
