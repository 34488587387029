import React from "react";
import LeftAnimatorBox from "./animatorbox/LeftAnimatorBox";
import Certification from "../homePageComponents/animationFiles/certification.json";
import Compliances from "../homePageComponents/animationFiles/compliances.json";
import RightContentBox from "./animatorbox/RightContentBox";
import { icon1, icon2, icon3 } from "..";
function ConsultationWrap() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Certification,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: Compliances,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: Compliances,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section id="consultationSection">
      <div className="container">
        <div className="row">
          <div className="col-md-5" data-aos="fade-right">
            <LeftAnimatorBox options={defaultOptions2} />
          </div>
          <div className="col-md-4" data-aos="fade-up">
            <RightContentBox
              imgIcon={icon2}
              animeMainTitle="Compliances"
              animeSmallTitle="Business Growth"
              animePara="Ensures protection of digital assets. Organizations must comply with regulations, establish security policies, controls, and training programs to minimize risks."
              greenBtnLinkText="Get A Consultation"
              btnClass="btn w-50 consultBtn"
              consultLink="#"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5" data-aos="fade-right">
            <LeftAnimatorBox options={defaultOptions} />
          </div>
          <div className="col-md-4" data-aos="fade-up">
            <RightContentBox
              imgIcon={icon3}
              animeMainTitle="Certification"
              animeSmallTitle="Investors In People Growth"
              animePara="Certification is a process of validating a person’s or organization’s knowledge, skills, or abilities through an accredited program or examination."
              greenBtnLinkText="Get A Consultation"
              btnClass="btn w-50 consultBtn"
              consultLink="#"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5" data-aos="fade-right">
            <LeftAnimatorBox options={defaultOptions3} />
          </div>
          <div className="col-md-4" data-aos="fade-up">
            <RightContentBox
              imgIcon={icon1}
              animeMainTitle="Safety and Reliability"
              animeSmallTitle="Social Media Analysis"
              animePara="Safety and reliability refer to the measures and assurance of the protection and dependability of products, systems, or services for their intended use."
              greenBtnLinkText="Get A Consultation"
              btnClass="btn w-50 consultBtn"
              consultLink="#"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConsultationWrap;
