import React from "react";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import StickyHeader from "../components/header/StickyHeader";
import VAPTimg from "../components/innerpagecomponents/VAPTimg";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";

function PenetrationTestingService() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Testing Service"
            bannerTitle="Penetration Testing Service"
            bannerPara="Penetration testing stands as a highly effective means of identifying vulnerabilities in your system."
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      <section className="conetentSection">
        <AboutPage
          line1="Yet, to carry out an exceptionally advanced penetration test, you require the expertise of a proficient security service provider like Vodote Techa. Numerous other service providers make grand claims but often fall short in delivering. Our esteemed clientele serves as a testament to the excellence we consistently deliver through our services."
          line2="While there are numerous VAPT services available in Mumbai, what truly distinguishes us is our exceptional capacity to perform penetration testing at a level unmatched by other security firms. We take great pride in having some of the most brilliant security experts leading our efforts, capable of undertaking any security assessment test to fortify your business's security. Our tests encompass nearly all vulnerabilities that a hacker might potentially exploit, ensuring comprehensive protection for your business. We employ the most advanced penetration testing tools
                    available in the security industry to ensure that no
                    vulnerabilities are left exposed to potential hackers. "
          abImg={otherImg}
          altName="Penetration Testing Service"
        />
        <section className="ourMethodologySection pt-0">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12">
                <div className="methodparaWrap">
                  <p>
                    Our esteemed clientele has consistently benefited from our
                    top-tier services. We place a strong emphasis on providing
                    high-quality services at highly competitive prices. Rest
                    assured, your company's IT security is our top priority, and
                    we offer a comprehensive range of services to maintain its
                    integrity. From IT security audits to VAPT tests and even
                    web application security, Vodote Techa covers
                    it all. Our domain experts are seasoned professionals you
                    can trust not to disappoint.
                  </p>
                  <p>
                    Neglecting your company's IT security leaves it vulnerable
                    to potential hackers who may exploit security gaps to access
                    your confidential data—a situation no business desires.
                    Entrust Vodote Techa to manage your company's
                    IT security; we are the premier VAPT company in Mumbai. Our
                    advanced penetration testing will uncover vulnerabilities in
                    your company's IT systems and architecture, providing you
                    with essential insights to bolster your security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <VAPTimg />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />

      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default PenetrationTestingService;
