import React from "react";
import BannertextBox from "../components/innerpagecomponents/BannertextBox";
import BannerFloatImg from "../components/innerpagecomponents/BannerFloatImg";
import { floatImg1, formImg, otherImg } from "../components";
import AboutPage from "../components/innerpagecomponents/AboutPage";
import StickyHeader from "../components/header/StickyHeader";
import QuestionAnswers from "../components/innerpagecomponents/QuestionAnswers";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import SuccessCountSection from "../components/innerpagecomponents/SuccessCountSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";

function WebApplicationSecurity() {
  return (
    <div className="innervas">
      <section className="innerBannerSection">
        <div className="container">
          <BannertextBox
            span="Security Service"
            bannerTitle="Web Application Security"
            bannerPara="Web applications are dynamic, operational products that users engage with routinely."
          />
          <BannerFloatImg floatImg={floatImg1} />
        </div>
      </section>
      <section className="conetentSection">
        <AboutPage
          line1="Web applications are living, functional tools that users regularly interact with. Regardless of the specific kind of web application being utilized, there will always be users, both within and outside the organization. These applications manage substantial amounts of data, sometimes of a sensitive nature. "
          line2="It's of utmost importance to make certain that unauthorized individuals cannot misuse the application to gain access to confidential data for malicious intents. To safeguard your web application comprehensively and shield it from diverse cybersecurity risks, it is imperative to deploy a robust, state-of-the-art web application security solution that delivers outstanding protection."
          abImg={otherImg}
          altName="Web Application Security"
        />
      </section>
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <section className="ourMethodologySection">
        <div className="container">
        <QuestionAnswers
            que1="What are the steps involved in ensuring the security of a web application?"
            ans11="Dissimilar to other website security services available in Mumbai, our approach to assessing your application's security sets us apart. At Vodote Techa, we employ a variety of techniques to ensure the comprehensive security of your application. Some of the widely used methods we employ to identify potential threats in your web application encompass fault injection, black-box testing, behavior monitoring, and more. These tests have a proven track record of detecting vulnerabilities of all kinds, allowing us to promptly address them and provide you with a secure website."
            ans12="We also provide training to your developers on secure web development practices, ensuring that your web application is constructed with security in mind from the very beginning. In addition to this, we enforce stringent security standards endorsed by global security institutions, guaranteeing that your web application consistently upholds the highest security standards."
            ans13="The source code forms the foundation of your web application, and any vulnerabilities within it can undermine all prevention techniques. Therefore, we meticulously review your source code to identify and address any potential threats that could pose a risk to your security."
            que2="Why Vodote Techa is the Optimal Choice for Ensuring Web Application Security?"
            ans21="Securing web applications can be a complex endeavor due to the multitude of factors involved. With the online landscape, the frequency of hacking attempts is notably high, and the tactics employed by hackers are constantly evolving. It requires a skilled team of experts to effectively safeguard your applications. At Vodote Techa, we boast a professional team with extensive experience, ensuring the utmost security for your web applications."
            ans22="In addition to web application security, our expertise extends to a range of related services, including website security assessments, vulnerability assessments, penetration testing services, and comprehensive security training for your company's employees. By entrusting your security needs to a single provider, you can access a comprehensive suite of services all under one roof."
            ans23="If you would like to learn more about our advanced security testing services, please don't hesitate to contact us at +91 7249643835 or send an email to info@vodote.com"
          />
        </div>
      </section>
      <OurClients />
      <section className="webSecuritySection">
        <WebsiteSecuritySection title="Strengthening Website Security" para="Best Practices for Effective Website Security Management"/>
      </section>
      <ChartSection />
      <SuccessCountSection />
      <OrganisationSection />
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default WebApplicationSecurity;
