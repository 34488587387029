import React, { useEffect, useRef, useState } from "react";
import {
  headerlogo,
  navIcon1,
  navIcon2,
  navIcon3,
  navIcon4,
  navIcon5,
  navIcon6,
  navIcon7,
  navIcon8,
  navIcon9,
} from "..";
import OrangeBtn from "../cta/OrangeBtn";
import { Link, useLocation } from "react-router-dom";
function NavigationBar() {
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(0);
  const [scroll, setScroll] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    setIsNavOpen(false);
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });

    const handleScroll = () => {
      const currentScrollpos = window.pageYOffset;

      if (prevScrollpos > currentScrollpos) {
        document.getElementById("header").style.top = "0";
      } else if (prevScrollpos > 500) {
        document.getElementById("header").style.top = "-120px";
      }
      setPrevScrollpos(currentScrollpos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos, location]);

  // Scroll to the top when the location changes (page redirect)
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <header id="header" className={scroll ? "header" : "header topClass"}>
      <section id="navigationBar">
        <div className="container">
          <nav className="nav_bar navbar navbar-expand-lg navbar-light">
            <Link
              to="/"
              className="navbar-brand"
              ref={scrollRef}
              onClick={scrollToTop}
            >
              <img
                src={headerlogo}
                alt="vodote logo"
                className="img-fluid"
                width="180"
                height="116"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="menuToggle"
              onClick={() => setIsNavOpen(!isNavOpen)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
              id="basic-navbar-nav"
            >
              <div className="ms-auto gap-5 navbar-nav pe-5">
                <span className="drop_Down">
                  <Link
                    to="/"
                    role="button"
                    className={`nav-link ${isActive("/")}`}
                  >
                    Home
                  </Link>
                </span>
                <span className="drop_Down">
                  <Link
                    role="button"
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="servicesDrop0"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services
                    <span className="material-symbols-outlined">
                      stat_minus_1
                    </span>
                  </Link>
                  <div
                    className="drop_down_menus dropdown-menu"
                    aria-labelledby="servicesDrop0"
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <div className="drop_menu_innerBox">
                          <ul>
                            <li>
                              <Link
                                to="/services/vulnerability-assessment-service"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon1}
                                      alt="Vulnerability Assessment"
                                      width={40}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      Vulnerability Assessment
                                    </span>
                                    <span className="menu_desc">
                                      Systematic evaluation to uncover and
                                      address potential security risks.
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services/information-security-audit-service"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon2}
                                      alt="IT Security Audit OnDemand"
                                      width={40}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      IT Security Audit OnDemand
                                    </span>
                                    <span className="menu_desc">
                                      Ensuring digital defenses are always
                                      vigilant.
                                    </span>
                                    <br />
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services/web-application-penetration-testing"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon3}
                                      alt="Web Application Penetration Testing"
                                      width={40}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      Web App Penetration Testing
                                    </span>
                                    <span className="menu_desc">
                                      Proactively securing your online assets
                                      through thorough security assessment.
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="drop_menu_innerBox">
                          <ul>
                            <li>
                              <Link
                                to="/services/mobile-application-security-testing"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon4}
                                      alt="Mobile App Security Testing"
                                      width={45}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      Mobile App Security Testing
                                    </span>
                                    <span className="menu_desc">
                                      Safeguarding your mobile application
                                      against vulnerabilities and threats.
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services/web-application-security"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon5}
                                      alt="Web Application Security"
                                      width={40}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      Web Application Security
                                    </span>
                                    <span className="menu_desc">
                                      Safeguarding online systems and data from
                                      digital threats and breaches.
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services/penetration-testing-service"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon6}
                                      alt="Penetration Testing Service"
                                      width={40}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      Penetration Testing Service
                                    </span>
                                    <span className="menu_desc">
                                      Proactively identifying and strengthening
                                      security weaknesses in your digital
                                      infrastructure.
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="drop_menu_innerBox">
                          <ul>
                            <li>
                              <Link
                                to="/services/wireless-auditing"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon7}
                                      alt="Wireless Auditing"
                                      width={40}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      Wireless Auditing
                                    </span>
                                    <span className="menu_desc">
                                      Evaluating and enhancing the security of
                                      wireless networks.
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services/website-security-management"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon8}
                                      alt="Website Security Management"
                                      width={40}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      Website Security Management
                                    </span>
                                    <span className="menu_desc">
                                      Protecting and maintaining the integrity
                                      of your online presence.
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services/spear-phishing"
                                className="drop_menu_link"
                              >
                                <div className="menueWrap">
                                  <span>
                                    <img
                                      src={navIcon9}
                                      alt="Spear Phishing"
                                      width={40}
                                      height={40}
                                    />
                                  </span>
                                  <div className="menuTextWrap">
                                    <span className="menu_name">
                                      Spear Phishing
                                    </span>
                                    <span className="menu_desc">
                                      Targeted deception tactics aiming at
                                      specific individuals or organizations for
                                      cyberattacks.
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <span className="drop_Down">
                  <Link
                    to="/why-us"
                    role="button"
                    className={`nav-link ${isActive("/why-us")}`}
                  >
                    Why Us
                  </Link>
                </span>
                <span className="drop_Down">
                  <Link
                    to=""
                    role="button"
                    // className={`nav-link ${isActive("/about")}`}
                    className="nav-link"
                  >
                    About
                  </Link>
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between gap-5 d-none d-lg-block">
              <div className="getQuote">
                <OrangeBtn
                  redirecLink="/contact"
                  clsName="btn orgBtn"
                  btnName="Free Quote"
                />
              </div>
            </div>
          </nav>
        </div>
      </section>
    </header>
  );
}

export default NavigationBar;
