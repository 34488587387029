import React from "react";

function LeftDataBox() {
  return (
    <div className="col-md-6 px-0">
      <div className="leftBoxWrap">
        <h4 className="sectionTitle">Let's Make Things Happen</h4>
        <h5 className="sectionSubPara">
          Smarter Ways To Manage Your Technology
        </h5>
        <div className="divider"></div>
        <p className="authMsg">
          “The team at Vodote Techa is fabulous. They helped us unlock our
          potential data online and offline. We have experienced year on year
          growth due to their progressive approach.”
        </p>
        <div className="authNameWrap">
          <h5 className="authName">George Anderson</h5>
          <p className="authDesignation">Digitalbox CEO</p>
        </div>
      </div>
    </div>
  );
}

export default LeftDataBox;
