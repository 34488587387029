import React from "react";
import OurClients from "../components/homePageComponents/ourclients/OurClients";
import WebsiteSecuritySection from "../components/innerpagecomponents/WebsiteSecuritySection";
import AwardBox from "../components/awards/AwardBox";
import StickyHeader from "../components/header/StickyHeader";
import { formImg } from "../components";
import ChartSection from "../components/innerpagecomponents/ChartSection";
import FaqSection from "../components/faqs/FaqSection";
import OrganisationSection from "../components/homePageComponents/leadingorganisation/OrganisationSection";
import WhyUsBanner from "../components/whyuspagecomponents/banner/WhyUsBanner";
import MarketingStrategySection from "../components/whyuspagecomponents/marketingstrategy/MarketingStrategySection";
import ExpertTalk from "../components/whyuspagecomponents/ExpertTalk";
import SocialMediaSection from "../components/whyuspagecomponents/socialmediasection/SocialMediaSection";
import AbstractTestimonial from "../components/whyuspagecomponents/AbstractTestimonial";
import LeftDataBox from "../components/homePageComponents/footer-form/LeftDataBox";
import FooterForm from "../components/homePageComponents/footer-form/FooterForm";

function WhyUs() {
  return (
    <div className="whyusSection">
      <WhyUsBanner />
      <OurClients />
      <MarketingStrategySection />
      <section className="webSecuritySection">
        <WebsiteSecuritySection
          title="Secured Marketing Growth"
          para="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor placerat luctus."
        />
      </section>
      <section className="darkBG">
        <div className="awardSection">
          <div className="container">
            <h5>Awards</h5>
            <AwardBox />
          </div>
        </div>
      </section>
      <ExpertTalk />
      <StickyHeader
        profileImg={formImg}
        designation="Cyber Expert Consultant"
        name="Lois June"
        title="How Secure Are You?"
      />
      <SocialMediaSection/>
      <ChartSection />
      <FaqSection />
      <OrganisationSection />
      <AbstractTestimonial/>
      <section className="fooFormSection">
        <div className="container">
          <div className="row">
            <LeftDataBox />
            <FooterForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhyUs;
