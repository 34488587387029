import React from "react";
import { expertTalk } from "..";

function ExpertTalk() {
  return (
    <section className="expertTalkSection">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="talkLeftBox">
              <img src={expertTalk} alt="" className="img-fluid rounded-4" />
            </div>
          </div>
          <div className="col-md-4 offset-1">
            <div className="talkRightBox">
              <h5>
                “The team at Avada Marketing Consultant is fabulous. They helped
                us unlock our potential online and offline. We have experienced
                year on year growth due to their progressive approach.”
              </h5>
              <h6>Andreas Casey</h6>
              <span>Marketing Consultant Expert</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExpertTalk;
