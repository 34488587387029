import React, { useEffect, useState } from "react";
import { case_1, case_2, case_3, case_4, case_5 } from "../..";
import { useLocation } from "react-router-dom";

const organizations = [
  {
    id: 0,
    org_Img: case_1,
    org_title:
      "How Spaces attracted five million visitors by improving the content",
    org_num: "200%",
    org_subtitle: "Higher revenue from digital",
    org_link: "#",
  },
  {
    id: 1,
    org_Img: case_2,
    org_title:
      "Creativity helped Hemisferio to increase their brand reach vertically",
    org_num: "10x",
    org_subtitle: "Sales increase with the same ad spend",
    org_link: "#",
  },
  {
    id: 2,
    org_Img: case_3,
    org_title: "How DigitalBox used AI-powered data insight to boost sales",
    org_num: "3-year",
    org_subtitle: "Partnership with Avada Consultant",
    org_link: "#",
  },
];
const organizations2 = [
  {
    id: 0,
    org_Img: case_1,
    org_title:
      "Creativity helped Hemisferio to increase their brand reach vertically",
    org_num: "10x",
    org_subtitle: "Sales increase with the same ad spend",
    org_link: "#",
  },
  {
    id: 1,
    org_Img: case_4,
    org_title:
      "How Next used social media to turn haters into loyal fans and ambassadors",
    org_num: "100%",
    org_subtitle: "Annual growth with 40 hours week",
    org_link: "#",
  },
  {
    id: 2,
    org_Img: case_5,
    org_title: "How Abstract reached a global audience with a minuscule budget",
    org_num: "80%",
    org_subtitle: "Revenue Increase",
    org_link: "#",
  },
];
// console.log(organizations);
function OrganisationCard() {
  const location = useLocation();
  const { pathname } = location;
  const [isDisplay, setIsDisplay] = useState(false);

  useEffect(() => {
    if (pathname === "/") {
      setIsDisplay(true);
    } else if (pathname === "/services/vulnerability-assessment-service") {
      setIsDisplay(false);
    } else {
      setIsDisplay(true);
    }
  }, [pathname]);
  return (
    <div className="row">
      {isDisplay ? (
        <>
          {organizations.map((item, index) => {
            return (
              <div className="orgCard" key={index}>
                <div className="innerOrgCard">
                  <div className="orgImgWrap">
                    <a href={item.org_link}>
                      <img
                        src={item.org_Img}
                        alt={item.org_subtitle}
                        className="img-fluid"
                      />
                      <div className="overlay"></div>
                    </a>
                  </div>
                  <a href={item.org_link} className="orgLink">
                    <h3>{item.org_title}</h3>
                  </a>
                  <h4>{item.org_num}</h4>
                  <p>{item.org_subtitle}</p>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {organizations2.map((item, index) => {
            return (
              <div className="orgCard" key={index}>
                <div className="innerOrgCard">
                  <div className="orgImgWrap">
                    <a href={item.org_link}>
                      <img
                        src={item.org_Img}
                        alt={item.org_subtitle}
                        className="img-fluid"
                      />
                      <div className="overlay"></div>
                    </a>
                  </div>
                  <a href={item.org_link} className="orgLink">
                    <h3>{item.org_title}</h3>
                  </a>
                  <h4>{item.org_num}</h4>
                  <p>{item.org_subtitle}</p>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default OrganisationCard;
