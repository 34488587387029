import React from 'react'
import SuccessCountBox from './SuccessCountBox'

function SuccessCountSection() {
  return (
    <div className='successCountSection'>
        <div className='container'>
          <SuccessCountBox/>
        </div>
    </div>
  )
}

export default SuccessCountSection