import React from "react";
import FaqsAccordion from "./FaqsAccordion";

function FaqSection() {
  const accordionItems = [
    {
      title:
        "Vivamus id ante vel purus commodo cursus? Nunc sit amet cursus massa?",
      content:
        "Suspendisse consectetur, est sed lobortis dictum, turpis orci finibus felis, mattis tempus ex dui ac diam. Etiam dictum turpis tempor, bibendum lacus eget, ullamcorper eros. Proin vitae viverra metus. In sed risus vehicula, tincidunt sem vel, dignissim orci. Cras porttitor erat sit amet nisl fermentum, at pharetra lorem blandit.",
    },
    {
      title:
        "Quisque aliquet, libero consequat elementum convallis, erat risus imperdiet?",
      content:
        "Suspendisse consectetur, est sed lobortis dictum, turpis orci finibus felis, mattis tempus ex dui ac diam. Etiam dictum turpis tempor, bibendum lacus eget, ullamcorper eros. Proin vitae viverra metus. In sed risus vehicula, tincidunt sem vel, dignissim orci. Cras porttitor erat sit amet nisl fermentum, at pharetra lorem blandit.",
    },
    {
      title: "Duis vel risus egestas, viverra enim sed, pretium velit?",
      content:
        "Suspendisse consectetur, est sed lobortis dictum, turpis orci finibus felis, mattis tempus ex dui ac diam. Etiam dictum turpis tempor, bibendum lacus eget, ullamcorper eros. Proin vitae viverra metus. In sed risus vehicula, tincidunt sem vel, dignissim orci. Cras porttitor erat sit amet nisl fermentum, at pharetra lorem blandit.",
    },
    {
      title:
        "Mauris porta interdum risus, at scelerisque ante faucibus porttitor erat sit?",
      content:
        "Suspendisse consectetur, est sed lobortis dictum, turpis orci finibus felis, mattis tempus ex dui ac diam. Etiam dictum turpis tempor, bibendum lacus eget, ullamcorper eros. Proin vitae viverra metus. In sed risus vehicula, tincidunt sem vel, dignissim orci. Cras porttitor erat sit amet nisl fermentum, at pharetra lorem blandit.",
    },
    {
      title:
        "Suspendisse sed ligula sed est ornare gravida varius natoque magnis?",
      content:
        "Suspendisse consectetur, est sed lobortis dictum, turpis orci finibus felis, mattis tempus ex dui ac diam. Etiam dictum turpis tempor, bibendum lacus eget, ullamcorper eros. Proin vitae viverra metus. In sed risus vehicula, tincidunt sem vel, dignissim orci. Cras porttitor erat sit amet nisl fermentum, at pharetra lorem blandit.",
    },
    {
      title:
        "Curabitur ornare felis sit amet neque volutpat, porta lobortis elit rutrum?",
      content:
        "Suspendisse consectetur, est sed lobortis dictum, turpis orci finibus felis, mattis tempus ex dui ac diam. Etiam dictum turpis tempor, bibendum lacus eget, ullamcorper eros. Proin vitae viverra metus. In sed risus vehicula, tincidunt sem vel, dignissim orci. Cras porttitor erat sit amet nisl fermentum, at pharetra lorem blandit.",
    },
    {
      title:
        "Praesent posuere nisi risus auctor posuere? Etiam auctor laoreet magna?",
      content:
        "Suspendisse consectetur, est sed lobortis dictum, turpis orci finibus felis, mattis tempus ex dui ac diam. Etiam dictum turpis tempor, bibendum lacus eget, ullamcorper eros. Proin vitae viverra metus. In sed risus vehicula, tincidunt sem vel, dignissim orci. Cras porttitor erat sit amet nisl fermentum, at pharetra lorem blandit. Suspendisse consectetur, est sed lobortis dictum, turpis orci finibus felis, mattis tempus ex dui ac diam. Etiam dictum turpis tempor, bibendum lacus eget, ullamcorper eros. Proin vitae viverra metus. In sed risus vehicula, tincidunt sem vel, dignissim orci. Cras porttitor erat sit amet nisl fermentum, at pharetra lorem blandit.",
    },
  ];
  return (
    <section className="faqSection">
      <div className="container">
        <div className="sectionTitle">
          <h4>What problem are you trying to solve?</h4>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="accordionWrap">
              <FaqsAccordion items={accordionItems} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqSection;
